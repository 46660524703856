import { TableCell, TableRow } from '@material-ui/core';
import React from 'react';
import { prettifyAmount } from '../../../constants/utils';

const ConsultantRow = (props) => {
  const snapshotData = {
      ...props.snapshotData,
      walletBalance: "Loading...",
      canShowBankInfo: false
  }

  return (
    <>
      {snapshotData.isEmpty ? 
        <TableRow style={{ height: 50 }} />
      :
        <TableRow>
            <TableCell className='string_column'>{snapshotData.name}</TableCell>
            <TableCell className='string_column'>{snapshotData.email}</TableCell>
            <TableCell className='string_column'>{new Date(snapshotData.createdAt).toLocaleString()}</TableCell>
            <TableCell className='string_column'>{snapshotData.profileLink}</TableCell>
            <TableCell className='numerar_column'>{prettifyAmount(snapshotData.consultantLocale, snapshotData.allTimeTotal)}</TableCell>
            <TableCell className='numerar_column'>{prettifyAmount(snapshotData.consultantLocale, snapshotData.consultantAllTimeTotal)}</TableCell>
            <TableCell className='numerar_column'>{prettifyAmount(snapshotData.consultantLocale, snapshotData.lokiAllTimeTotal)}</TableCell>
            <TableCell className='numerar_column'>{prettifyAmount(snapshotData.consultantLocale, snapshotData.teamAllTimeTotal)}</TableCell>
            <TableCell className='numerar_column'>{prettifyAmount(snapshotData.consultantLocale, snapshotData.stripeAllTimeTotal)}</TableCell>
            <TableCell className='numerar_column'>{prettifyAmount(snapshotData.consultantLocale, snapshotData.totalWithdrawn)}</TableCell>
            <TableCell className='numerar_column'>{prettifyAmount(snapshotData.consultantLocale, snapshotData.walletBallance)}</TableCell>
        </TableRow>
      }
    </>
  )
}

export default ConsultantRow;
