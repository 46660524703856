import React, { useEffect, useState } from 'react';
import firebase from 'firebase/app'
import ClipLoader from "react-spinners/ClipLoader";
import { Button, Dialog, DialogActions, DialogContent, TableBody, TableCell, TableHead, TableRow, TextField } from '@material-ui/core';
import TableComponent from '../TableComponent';
import CreditRow from './components/CreditRow';

const Credits = () => {
  const [creditCoupons, setCreditCoupons] = useState([])
  const [creditCouponsCount, setCreditCouponsCount] = useState(0)
  const [isFetchingData, setIsFetching] = useState(false)
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(200);
  const [open, setOpen] = useState(false);
  const [nCoupons, setNCoupons] = useState(1);
  const [refresh, setRefresh] = useState(false);
  const [generating, setGenerating] = useState(false);

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    console.log('Counter shards started fetching.')
    firebase.firestore().doc('/counters/credit_coupons').onSnapshot(snapshot => {
      setCreditCouponsCount(snapshot.data().count)
    }, error => {
      console.log('Error fetching counter shards:', error)
    })
  }, [])

  useEffect(() => {
    const offset = rowsPerPage * page
    const toSend = { limit: rowsPerPage, offset: offset }
    console.log("Fetch started:", performance.now())

    setIsFetching(true)
    setCreditCoupons([])

    firebase.functions().httpsCallable('getCreditCoupons')(toSend).then(function (result) {
        const data = result.data
        const notUsed = data.filter(a => !a.expireDateCredit)
        const used = data.filter(a => a.expireDateCredit).sort((a, b) => new Date(b.expireDateCredit).getTime() - new Date(a.expireDateCredit).getTime())
        const stats = notUsed.concat(used)
        console.log("Credit coupons fetched:", stats.length, performance.now())

        setIsFetching(false)
        setCreditCoupons(stats)
        console.log("STATS COUNT", stats.length)
      }).catch(error => {
        setIsFetching(false)
        alert(error)
      });
  }, [page, rowsPerPage, refresh])

  const generateCreditCoupons = async () => {
    setGenerating(true)

    for (let i = 0; i < nCoupons; i++) {
      await firebase.firestore().collection('credit_coupons').add({
        code: generateCode(),
        credit: 1000,
        locale: 'us'
      })
    }

    await firebase.firestore().doc(`/counters/credit_coupons`).update({
        count: firebase.firestore.FieldValue.increment(nCoupons)
    })

    setGenerating(false)
    setNCoupons(1)
    setOpen(false)
    setRefresh(oldRefresh => !oldRefresh)
  }

  const generateCode = () => {
      let result = '';
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
      const charactersLength = characters.length;
      for ( let i = 0; i < 5; i++ ) {
          result += characters.charAt(Math.floor(Math.random() * charactersLength));
      }
      return result;
  }

  const MyTableHead = () => {
    return (
      <TableHead>
        <TableRow>
          <TableCell className='string_column'>Code</TableCell>
          <TableCell className='string_column'>Client Name</TableCell>
          <TableCell className='string_column'>Status</TableCell>
          <TableCell className='numerar_column'>Credit</TableCell>
          <TableCell className='string_column'>Actions</TableCell>
        </TableRow>
      </TableHead>
    )
  }

  const MyTableBody = () => {
    return (
      <TableBody>
        {creditCoupons.map((element, index) => (
          <CreditRow key={index} snapshotData={element} setRefresh={setRefresh} />
        ))}
      </TableBody>
    )
  }

  return (
    <div>
      <h1 id="homepage-title">Credit coupons</h1>
      
      <div className='generate_button'>
        <Button color='primary' onClick={() => setOpen(true)}>Generate credit coupons</Button>
      </div>

      {isFetchingData ?
        <div className="homepage-loader">
          <ClipLoader size={30} color={"#3f51b5"} loading={isFetchingData} />
        </div>
      :
        <TableComponent
          tableHead={<MyTableHead />}
          tableBody={<MyTableBody />}
          count={creditCouponsCount}
          page={page}
          handleChangePage={handleChangePage}
          rowsPerPage={rowsPerPage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          rootClass='credits_table'
        />
      }

      {open &&
      <Dialog open={open} onClose={() => setOpen(false)} aria-labelledby="form-dialog-title">
          <DialogContent className='generate_coupons_dialog'>
            <p>Number of credit coupons: </p>
            <TextField
              variant='standard'
              value={nCoupons}
              onChange={(e) => {
                setNCoupons(parseInt(e.target.value))
              }}
              type='number'
              InputProps={{
                  inputProps: { 
                      min: 1
                  }
              }}
            />
          </DialogContent>
          <DialogActions>
              <Button onClick={() => setOpen(false)} color="white">
                  Cancel
              </Button>
              <Button disabled={generating} onClick={generateCreditCoupons} color="primary">
                  Generate
              </Button>
          </DialogActions>
      </Dialog>
      }
    </div>
  )
};

export default Credits;
