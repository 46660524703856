import React, { useContext, useEffect, useState } from 'react';
import './styles.css';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Navigation from '../Navigation';
import * as ROUTES from '../../constants/routes';
import SignInPage from '../SignIn';
import HomePage from '../Home';
import ConsultantList from '../Consultants';
import ClientsList from '../Clients';
import Corporate from '../Corporate';
import { FirebaseContext } from '../Firebase';
import firebase from 'firebase/app'
import { CircularProgress } from '@material-ui/core';
import Credits from '../Credits';

const App = () => {
  const context = useContext(FirebaseContext)
  const [isGettingAuthUser, setIsGettingAuthUser] = useState(true)
  const [isFetching, setIsFetching] = useState(true)
  const [authUser, setAuthUser] = useState(null)

  useEffect(() => {
    context.auth.onAuthStateChanged(async authUser => {
      setIsGettingAuthUser(true)
      setIsFetching(true)
      if (!authUser) {
        setAuthUser(null)
        setIsGettingAuthUser(false)
        return
      }
      try {
        const result = await firebase.functions().httpsCallable('checkAdmin')()
        if (result.data.isAdmin) {
          setAuthUser(authUser)
          setIsFetching(false)
        } else {
          alert("Invalid admin username or password!")
          setAuthUser(null)
          context.auth.signOut()
        }
      } catch(error) {
        alert("Invalid admin username or password!")
        setAuthUser(null)
        context.auth.signOut()
      }
      setIsGettingAuthUser(false)
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Router>
      <div>
        <Navigation authUser={authUser} setAuthUser={setAuthUser} isFetching={isFetching} />
        {isGettingAuthUser ? 
          <div className='loading_container'>
            <CircularProgress size={50} />
          </div>
        :
          <Switch>
            {authUser && !isFetching? 
              <>
                <Route path={ROUTES.HOME} exact component={HomePage} />
                <Route path={ROUTES.CONSULTANTS} exact component={ConsultantList} />
                <Route path={ROUTES.CLIENTS} exact component={ClientsList} />
                <Route path={ROUTES.CORPORATE} exact component={Corporate} />
                <Route path={ROUTES.CREDITS} exact component={Credits} />
              </>
            :
              <Route path={ROUTES.SIGN_IN} render={() => <SignInPage setIsFetching={setIsFetching} setAuthUser={setAuthUser} />} />
            }
          </Switch>
        }
      </div>
    </Router>
  )
};

export default App;
