import React, { useState } from 'react';
import { Button, CircularProgress, TableCell, TableRow } from '@material-ui/core';
import moment from 'moment';
import firebase from 'firebase/app'

const CreditRow = (props) => {
    const { snapshotData, setRefresh } = props

    const [deleting, setDeleting] = useState(false)

    const deleteCreditCoupon = async () => {
      setDeleting(true)
      await firebase.firestore().doc(`credit_coupons/${snapshotData.id}`).delete()
      await firebase.firestore().doc(`/counters/credit_coupons`).update({
          count: firebase.firestore.FieldValue.increment(-1)
      })
      setDeleting(false)
      setRefresh(oldRefresh => !oldRefresh)
    }

    const displayStatus = (date) => {
      if (date) {
        const now = moment()
        const expireDays = Math.floor(moment.duration(moment(date).diff(now)).asDays())
  
        return (
          <>
            {expireDays < 0 ? 
              <span className='color_red'>Expired</span>
            :
              <>Expires {expireDays === 0 ? <b>&nbsp;today</b> : <>in&nbsp;<b>{expireDays}</b>&nbsp;day{expireDays > 1 && 's'}</>}</>
            }
          </>
        )
      }

      return 'Not used'
    }

    return (
        <>
            {snapshotData.isEmpty ? 
                    <TableRow style={{ height: 50 }} />
                :
                    <TableRow>
                        <TableCell className='numerar_column'>{snapshotData.code}</TableCell>
                        <TableCell className='string_column'>{snapshotData.clientName ?? '-'}</TableCell>
                        <TableCell className='string_column'>{displayStatus(snapshotData.expireDateCredit)}</TableCell>
                        <TableCell className='numerar_column'><b>${snapshotData.credit / 100}</b></TableCell>
                        <TableCell className='string_column'>
                          <Button className='delete_button' onClick={deleteCreditCoupon}>
                            {deleting ? <CircularProgress size={10} /> : 'DELETE'}
                          </Button>
                        </TableCell>
                    </TableRow>
            }
        </>
    )

}

export default CreditRow;
