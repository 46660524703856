import React, { useEffect, useState } from 'react';
import firebase from 'firebase/app'
import ClientRow from './components/ClientRow';
import ClipLoader from "react-spinners/ClipLoader";
import { emptyState, localeToCurrency } from "./../../constants/utils"
import { TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import TableComponent from '../TableComponent';
import config from '../../config/config';

const Clients = () => {
  const [clients, setClients] = useState([])
  const [clientsCount, setClientsCount] = useState(0)
  const [isFetchingData, setIsFetching] = useState(false)
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(200);

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    console.log('Counter shards started fetching.')
    firebase.firestore().doc('/counters/clients').onSnapshot(snapshot => {
      setClientsCount(snapshot.data().count)
    }, error => {
      console.log('Error fetching counter shards:', error)
    })
  }, [])

  useEffect(() => {
    const offset = rowsPerPage * page
    const toSend = { limit: rowsPerPage, offset: offset }
    console.log("Fetch started:", performance.now())

    setIsFetching(true)
    setClients([])

    firebase.functions().httpsCallable('getStatsForClients')(toSend).then(function (result) {
        let stats = result.data.stats.map(stat => JSON.parse(stat))

        console.log("Clients fetched:", stats.length, performance.now())

        if (offset === page * rowsPerPage) {
          stats = stats.sort((a, b) => {
            return a["debt"] < b["debt"] ? 1 : -1
          })

          const totalStats = {}
          for (let state of stats) {
            for (let locale in state["moneySpent"]) {
              const currency = localeToCurrency(locale)
              if (!totalStats[currency]) {
                totalStats[currency] = {
                  name: "TOTAL (" + currency + "):",
                  clientLocale: locale,
                  spent: 0,
                  paid: 0,
                  debt: 0,
                  clientID: "x" + Math.random(),
                  isTotal: true
                }
              }
              if (!isNaN(parseInt(state["moneySpent"][locale]))) {
                totalStats[currency]["spent"] = totalStats[currency]["spent"] + state["moneySpent"][locale]
                totalStats[currency]["clientLocale"] = locale
              }
            }

            for (let locale in state["moneyPaid"]) {
              const currency = localeToCurrency(locale)
              if (!totalStats[currency]) {
                totalStats[currency] = {
                  name: "TOTAL (" + currency + "):",
                  clientLocale: locale,
                  spent: 0,
                  paid: 0,
                  debt: 0,
                  clientID: "x" + Math.random(),
                  isTotal: true
                }
              }
              if (!isNaN(parseInt(state["moneyPaid"][locale]))) {
                totalStats[currency]["paid"] = totalStats[currency]["paid"] + state["moneyPaid"][locale]
                totalStats[currency]["clientLocale"] = locale
              }
            }

            for (let locale in state["moneyDebt"]) {
              const currency = localeToCurrency(locale)
              if (!totalStats[currency]) {
                totalStats[currency] = {
                  name: "TOTAL (" + currency + "):",
                  clientLocale: locale,
                  spent: 0,
                  paid: 0,
                  debt: 0,
                  clientID: "x" + Math.random(),
                  isTotal: true
                }
              }
              if (!isNaN(parseInt(state["moneyDebt"][locale]))) {
                totalStats[currency]["debt"] = totalStats[currency]["debt"] + state["moneyDebt"][locale]
                totalStats[currency]["clientLocale"] = locale
              }
            }
          }

          stats.push(emptyState())

          for (let property in totalStats) {
            const state = totalStats[property]
            stats.push(state)
          }

          setIsFetching(false)
          setClients(stats)
          console.log("STATS COUNT", stats.length)
        }
      }
      ).catch(error => {
        setIsFetching(false)
        alert(error)
      });
  }, [page, rowsPerPage])

  const MyTableHead = () => {
    return (
      <TableHead>
        <TableRow>
          <TableCell className='string_column'>User</TableCell>
          <TableCell className='string_column'>Email</TableCell>
          {config.type === 'astrologer' &&
            <>
              <TableCell className='string_column'>BirthDay</TableCell>
              <TableCell className='string_column'>Birth place</TableCell>
            </>
          }
          <TableCell className='numerar_column'>Spent</TableCell>
          <TableCell className='numerar_column'>Paid</TableCell>
          <TableCell className='numerar_column'>Owing</TableCell>
          <TableCell className='kind_column'>Kind</TableCell>
          <TableCell className='date_column'>Last Failed Payment</TableCell>
          <TableCell className='date_column'>Last Call Session</TableCell>
        </TableRow>
      </TableHead>
    )
  }

  const MyTableBody = () => {
    return (
      <TableBody>
        {clients.map((element, index) => (
          <ClientRow key={index} snapshotData={element} />
        ))}
      </TableBody>
    )
  }

  return (
    <div>
      <h1 id="homepage-title">Clients</h1>

      {isFetchingData ?
        <div className="homepage-loader">
          <ClipLoader size={30} color={"#3f51b5"} loading={isFetchingData} />
        </div>
      :
        <TableComponent
          tableHead={<MyTableHead />}
          tableBody={<MyTableBody />}
          count={clientsCount}
          page={page}
          handleChangePage={handleChangePage}
          rowsPerPage={rowsPerPage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          rootClass={`client_table ${config.type === 'astrologer' ? 'client_table_big' : ''}`}
        />
      }
    </div>
  )
};

export default Clients;
