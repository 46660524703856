import { countries } from "./countries"

export const formattedInteger = (integer) => {
  let decimalPart = "" + (integer % 100)
  const toRet = "" + Math.floor(integer / 100)
  if (decimalPart.length === 1) {
      decimalPart = "0" + decimalPart
  }
  if (decimalPart.length === 2 && decimalPart[1] === '0') {
      decimalPart = "" + decimalPart[0]
  }
  if (decimalPart === "0") {
      return toRet
  }
  return toRet + "," + decimalPart
}

export const localeToCurrency = (locale) => {
    const currency = countries.find(each => each.code.toLowerCase() === locale.toLowerCase()).currency
    return currency
}

export const emptyState = () => {
  return {
    isEmpty: true,
  }
}

export const prettifyAmount = (locale, numberAmount) => {
    if (!numberAmount || !locale) {
        return "0"
    }
    
    const currency = localeToCurrency(locale)
    const prettyAmount = (numberAmount / 100).toLocaleString('en-US') + " " + currency
    return prettyAmount
}
