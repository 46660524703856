import React from 'react';
import { Paper, Table, TableContainer, TablePagination } from '@material-ui/core';

const TableComponent = (props) => {
  const { tableHead, tableBody, count, page, handleChangePage, rowsPerPage, handleChangeRowsPerPage, rootClass } = props

  return (
    <>
      <TableContainer component={Paper} classes={{
        root: `homepage_table ${rootClass}`
      }}>
        <Table size="small" stickyHeader>
          {tableHead}
          {tableBody}
        </Table>
      </TableContainer>
      <TablePagination
        className='table_pagination'
        rowsPerPageOptions={[50, 100, 200, 500, 1000]}
        component="div"
        count={count}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </>
  )
};

export default TableComponent;
