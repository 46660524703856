import React, { useState } from "react";
import Popup from "reactjs-popup";
import firebase from 'firebase';
import './RejectPopup.css';
import { Divider } from "@material-ui/core";

const RejectPopup = (props) => {
    const { withdrawalData, snapshotRef } = props
    const [open, setOpen] = useState(false)
    const [reason, setReason] = useState(null)

    const openModal = () => {
      setOpen(true)
    }

    const closeModal = () => {
      setOpen(false);
    }

    const handleReasonChange = (event) => {
      setReason(event.target.value);
    }
    
    const handleSubmit = (event) => {
        event.preventDefault();
        console.log('Rejected with reason:', reason);

        let newReason = "No reason provided."
        if (reason) {
          newReason = reason
        }

        const teamWithdrawalID = withdrawalData.teamWithdrawalID
        if(teamWithdrawalID && teamWithdrawalID) {
          // we reject a team withdrawal
          const toSend = {teamWithdrawalID: teamWithdrawalID, operation: "reject", rejectReason: newReason}
          props.setLoadingIndicator(true)
          firebase.functions().httpsCallable('updateTeamWithdrawal')(toSend).then(() => {
              console.log("REJECTED TEAM WITHDRAWAL")
              props.childUpdatedData()
            }).catch(error => {
              console.log("ERROR" + error)
            }).finally( () => {
              closeModal()
              props.setLoadingIndicator(false)
            });
        } else {
          // we reject a normal withdrawal
          props.setLoadingIndicator(true)
          firebase.firestore().collection('withdrawals').doc(snapshotRef).update({ status: "rejected", reason: reason }).then(() => {
            props.childUpdatedData()
            console.log("Updated succesfully. Status: rejected, Reason: " + reason)
          }).catch(error => {
            console.log("ERROR" + error)
          }).finally( () => {
            closeModal()
            props.setLoadingIndicator(false)
        });
        }
    }
  
    return (
      <>
        <button className="button" onClick={openModal}>
          Reject
        </button>
        <Popup
          open={open}
          closeOnDocumentClick
          onClose={closeModal}
        >
          <div className="modal">
            <div className="header"> Reject details </div>
            <Divider variant="middle" />
            <form className="form" onSubmit={handleSubmit} onClose={closeModal}>
              <h3 style={{marginLeft: '10%', textAlign: "left"}}>Reject reason:</h3>
              <label>
                  <textarea style={{width: "80%", height: "100pt", resize: 'none'}} value={reason} onChange={handleReasonChange} />
              </label>
              <div className="actions">
                <input type="submit" value="Submit" />
                <button
                        className="button"
                        onClick={() => {
                            console.log("modal closed ");
                            closeModal();
                        }}
                    >
                        Cancel
                </button>
              </div>
            </form>
          </div>
        </Popup>
      </>
    );
}
  
export default RejectPopup;
