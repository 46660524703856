import React from 'react';
import moment from 'moment';
import { localeToCurrency, prettifyAmount } from './../../../constants/utils';
import { TableCell, TableRow } from '@material-ui/core';
import config from '../../../config/config';

const ClientRow = (props) => {
    const snapshotData = {
        ...props.snapshotData,
        walletBalance: "Loading...",
        canShowBankInfo: false
    }

    const listAmounts = (dict) => {
        const currenciesDict = {}
        for (let property in dict) {
            const currency = localeToCurrency(property)
            if (currenciesDict[currency]) {
                currenciesDict[currency] = currenciesDict[currency] + dict[property]
            } else {
                currenciesDict[currency] = dict[property]
            }
        }

        const strings = []
        for (let property in currenciesDict) {
            const stringAmount = currenciesDict[property] ? currenciesDict[property].toString() : "0"
            const length = stringAmount.length
            const prettyAmount = (stringAmount === "0" ? "" : stringAmount.substring(0, length - 2) + ",") + stringAmount.substring(length - 2, length)
            strings.push(prettyAmount + " " + property)
        }

        return strings.join(" | ")
    }

    const dateString = (maybeMillis) => {
        const date = maybeMillis && maybeMillis ? moment(new Date(maybeMillis)).format("DD-MM-YYYY hh:mm") : "/"
        return date
    }

    return (
        <>
            {snapshotData.isEmpty ? 
                    <TableRow style={{ height: 50 }} />
                :
                    <TableRow>
                        <TableCell className='string_column'>{snapshotData.name}</TableCell>
                        <TableCell className='string_column'>{snapshotData.clientEmail}</TableCell>
                        {config.type === 'astrologer' &&
                            <>
                            <TableCell className='string_column'>{snapshotData.birthDate}</TableCell>
                            <TableCell className='string_column'>{snapshotData.address}</TableCell>
                            </>
                        }
                        <TableCell className='numerar_column'>
                            {snapshotData.isTotal ? prettifyAmount(snapshotData.clientLocale, snapshotData.spent) : listAmounts(snapshotData.moneySpent)}
                        </TableCell>
                        <TableCell className='numerar_column'>
                            {snapshotData.isTotal ? prettifyAmount(snapshotData.clientLocale, snapshotData.paid) : listAmounts(snapshotData.moneyPaid)}
                        </TableCell>
                        <TableCell className='numerar_column'>
                            {snapshotData.isTotal ? prettifyAmount(snapshotData.clientLocale, snapshotData.debt) : listAmounts(snapshotData.moneyDebt)}
                        </TableCell>
                        <TableCell className='kind_column'>{snapshotData.trickedUs ? <b style={{ color: "#FF0000"}}>TRICKSTER</b> : "GOOD GUY"}</TableCell>
                        <TableCell className='date_column'>{dateString(snapshotData.lastDebt)}</TableCell>
                        <TableCell className='date_column'>{dateString(snapshotData.lastSession)}</TableCell>
                    </TableRow>
            }
        </>
    )

}

export default ClientRow;
