
// From https://bitbucket.org/atlassian/atlaskit-mk-2/raw/4ad0e56649c3e6c973e226b7efaeb28cb240ccb0/packages/core/select/src/data/countries.js
export const countries = [
  {
    code: "AF",
    phone: "93",
    label: "Afghanistan",
    currency: "AFN",
  },
  {
    code: "AL",
    phone: "355",
    label: "Albania",
    currency: "ALL",
  },
  {
    code: "DZ",
    phone: "213",
    label: "Algeria",
    currency: "DZD",
  },
  {
    code: "AS",
    phone: "1-684",
    label: "American Samoa",
    currency: "USD",
  },
  {
    code: "AD",
    phone: "376",
    label: "Andorra",
    currency: "EUR",
  },
  {
    code: "AO",
    phone: "244",
    label: "Angola",
    currency: "USD",
  },
  {
    code: "AI",
    phone: "1-264",
    label: "Anguilla",
    currency: "XCD",
  },
  {
    code: "AG",
    phone: "1-268",
    label: "Antigua and Barbuda",
    currency: "XCD",
  },
  {
    code: "AR",
    phone: "54",
    label: "Argentina",
    currency: "ARS",
  },
  {
    code: "AM",
    phone: "374",
    label: "Armenia",
    currency: "AMD",
  },
  {
    code: "AW",
    phone: "297",
    label: "Aruba",
    currency: "AWG",
  },
  {
    code: "AU",
    phone: "61",
    label: "Australia",
    currency: "AUD",
  },
  {
    code: "AT",
    phone: "43",
    label: "Austria",
    currency: "EUR",
  },
  {
    code: "AZ",
    phone: "994",
    label: "Azerbaijan",
    currency: "AZN",
  },
  {
    code: "BS",
    phone: "1-242",
    label: "Bahamas",
    currency: "BSD",
  },
  {
    code: "BH",
    phone: "973",
    label: "Bahrain",
    currency: "BHD",
  },
  {
    code: "BD",
    phone: "880",
    label: "Bangladesh",
    currency: "BDT",
  },
  {
    code: "BB",
    phone: "1-246",
    label: "Barbados",
    currency: "BBD",
  },
  {
    code: "BY",
    phone: "375",
    label: "Belarus",
    currency: "BYN",
  },
  {
    code: "BE",
    phone: "32",
    label: "Belgium",
    currency: "EUR",
  },
  {
    code: "BZ",
    phone: "501",
    label: "Belize",
    currency: "BZD",
  },
  {
    code: "BJ",
    phone: "229",
    label: "Benin",
    currency: "XOF",
  },
  {
    code: "BM",
    phone: "1-441",
    label: "Bermuda",
    currency: "BMD",
  },
  {
    code: "BT",
    phone: "975",
    label: "Bhutan",
    currency: "USD",
  },
  {
    code: "BO",
    phone: "591",
    label: "Bolivia (Plurinational State of)",
    currency: "BOB",
  },
  {
    code: "BQ",
    phone: "599",
    label: "Bonaire, Sint Eustatius and Saba",
    currency: "USD",
  },
  {
    code: "BA",
    phone: "387",
    label: "Bosnia and Herzegovina",
    currency: "BAM",
  },
  {
    code: "BW",
    phone: "267",
    label: "Botswana",
    currency: "BWP",
  },
  {
    code: "BV",
    phone: "47",
    label: "Bouvet Island",
    currency: "NOK",
  },
  {
    code: "BR",
    phone: "55",
    label: "Brazil",
    currency: "BRL",
  },
  {
    code: "IO",
    phone: "246",
    label: "British Indian Ocean Territory",
    currency: "USD",
  },
  {
    code: "VG",
    phone: "1-284",
    label: "British Virgin Islands",
    currency: "USD",
  },
  {
    code: "BN",
    phone: "673",
    label: "Brunei Darussalam",
    currency: "BND",
  },
  {
    code: "BG",
    phone: "359",
    label: "Bulgaria",
    currency: "BGN",
  },
  {
    code: "BF",
    phone: "226",
    label: "Burkina Faso",
    currency: "XOF",
  },
  {
    code: "BI",
    phone: "257",
    label: "Burundi",
    currency: "USD",
  },
  {
    code: "CV",
    phone: "238",
    label: "Cabo Verde",
    currency: "CVE",
  },
  {
    code: "KH",
    phone: "855",
    label: "Cambodia",
    currency: "KHR",
  },
  {
    code: "CM",
    phone: "237",
    label: "Cameroon",
    currency: "USD",
  },
  {
    code: "CA",
    phone: "1",
    label: "Canada",
    currency: "CAD",
  },
  {
    code: "KY",
    phone: "1-345",
    label: "Cayman Islands",
    currency: "KYD",
  },
  {
    code: "CF",
    phone: "236",
    label: "Central African Republic",
    currency: "USD",
  },
  {
    code: "TD",
    phone: "235",
    label: "Chad",
    currency: "USD",
  },
  {
    code: "CL",
    phone: "56",
    label: "Chile",
    currency: "USD",
  },
  {
    code: "CN",
    phone: "86",
    label: "China",
    currency: "CNY",
  },
  {
    code: "HK",
    phone: "852",
    label: "China, Hong Kong Special Administrative Region",
    currency: "HKD",
  },
  {
    code: "MO",
    phone: "853",
    label: "China, Macao Special Administrative Region",
    currency: "MOP",
  },
  {
    code: "CX",
    phone: "61",
    label: "Christmas Island",
    currency: "AUD",
  },
  {
    code: "CC",
    phone: "61",
    label: "Cocos (Keeling) Islands",
    currency: "AUD",
  },
  {
    code: "CO",
    phone: "57",
    label: "Colombia",
    currency: "COP",
  },
  {
    code: "KM",
    phone: "269",
    label: "Comoros",
    currency: "USD",
  },
  {
    code: "CG",
    phone: "242",
    label: "Congo",
    currency: "USD",
  },
  {
    code: "CK",
    phone: "682",
    label: "Cook Islands",
    currency: "NZD",
  },
  {
    code: "CR",
    phone: "506",
    label: "Costa Rica",
    currency: "CRC",
  },
  {
    code: "HR",
    phone: "385",
    label: "Croatia",
    currency: "HRK",
  },
  {
    code: "CU",
    phone: "53",
    label: "Cuba",
    currency: "USD",
  },
  {
    code: "CW",
    phone: "599",
    label: "Curaçao",
    currency: "ANG",
  },
  {
    code: "CY",
    phone: "357",
    label: "Cyprus",
    currency: "EUR",
  },
  {
    code: "CZ",
    phone: "420",
    label: "Czechia",
    currency: "CZK",
  },
  {
    code: "CI",
    phone: "225",
    label: "Côte d'Ivoire",
    currency: "USD",
  },
  {
    code: "KP",
    phone: "850",
    label: "Democratic People's Republic of Korea",
    currency: "KPW",
  },
  {
    code: "CD",
    phone: "243",
    label: "Democratic Republic of the Congo",
    currency: "CDF",
  },
  {
    code: "DK",
    phone: "45",
    label: "Denmark",
    currency: "DKK",
  },
  {
    code: "DJ",
    phone: "253",
    label: "Djibouti",
    currency: "USD",
  },
  {
    code: "DM",
    phone: "1-767",
    label: "Dominica",
    currency: "XCD",
  },
  {
    code: "DO",
    phone: "1-809,1-829,1-849",
    label: "Dominican Republic",
    currency: "DOP",
  },
  {
    code: "EC",
    phone: "593",
    label: "Ecuador",
    currency: "USD",
  },
  {
    code: "EG",
    phone: "20",
    label: "Egypt",
    currency: "EGP",
  },
  {
    code: "SV",
    phone: "503",
    label: "El Salvador",
    currency: "USD",
  },
  {
    code: "GQ",
    phone: "240",
    label: "Equatorial Guinea",
    currency: "USD",
  },
  {
    code: "ER",
    phone: "291",
    label: "Eritrea",
    currency: "ERN",
  },
  {
    code: "EE",
    phone: "372",
    label: "Estonia",
    currency: "EUR",
  },
  {
    code: "SZ",
    phone: "268",
    label: "Eswatini",
    currency: "SZL",
  },
  {
    code: "ET",
    phone: "251",
    label: "Ethiopia",
    currency: "ETB",
  },
  {
    code: "FO",
    phone: "298",
    label: "Faroe Islands",
    currency: "DKK",
  },
  {
    code: "FJ",
    phone: "679",
    label: "Fiji",
    currency: "FJD",
  },
  {
    code: "FI",
    phone: "358",
    label: "Finland",
    currency: "EUR",
  },
  {
    code: "FR",
    phone: "33",
    label: "France",
    currency: "EUR",
  },
  {
    code: "GF",
    phone: "594",
    label: "French Guiana",
    currency: "EUR",
  },
  {
    code: "PF",
    phone: "689",
    label: "French Polynesia",
    currency: "USD",
  },
  {
    code: "TF",
    phone: "262",
    label: "French Southern Territories",
    currency: "EUR",
  },
  {
    code: "GA",
    phone: "241",
    label: "Gabon",
    currency: "USD",
  },
  {
    code: "GM",
    phone: "220",
    label: "Gambia",
    currency: "GMD",
  },
  {
    code: "GE",
    phone: "995",
    label: "Georgia",
    currency: "GEL",
  },
  {
    code: "DE",
    phone: "49",
    label: "Germany",
    currency: "EUR",
  },
  {
    code: "GH",
    phone: "233",
    label: "Ghana",
    currency: "GHS",
  },
  {
    code: "GI",
    phone: "350",
    label: "Gibraltar",
    currency: "GIP",
  },
  {
    code: "GR",
    phone: "30",
    label: "Greece",
    currency: "EUR",
  },
  {
    code: "GL",
    phone: "299",
    label: "Greenland",
    currency: "DKK",
  },
  {
    code: "GD",
    phone: "1-473",
    label: "Grenada",
    currency: "XCD",
  },
  {
    code: "GP",
    phone: "590",
    label: "Guadeloupe",
    currency: "EUR",
  },
  {
    code: "GU",
    phone: "1-671",
    label: "Guam",
    currency: "USD",
  },
  {
    code: "GT",
    phone: "502",
    label: "Guatemala",
    currency: "GTQ",
  },
  {
    code: "GG",
    phone: "44",
    label: "Guernsey",
    currency: "GBP",
  },
  {
    code: "GN",
    phone: "224",
    label: "Guinea",
    currency: "USD",
  },
  {
    code: "GW",
    phone: "245",
    label: "Guinea-Bissau",
    currency: "XOF",
  },
  {
    code: "GY",
    phone: "592",
    label: "Guyana",
    currency: "GYD",
  },
  {
    code: "HT",
    phone: "509",
    label: "Haiti",
    currency: "USD",
  },
  {
    code: "HM",
    phone: "672",
    label: "Heard Island and McDonald Islands",
    currency: "AUD",
  },
  {
    code: "VA",
    phone: "39-06",
    label: "Holy See",
    currency: "EUR",
  },
  {
    code: "HN",
    phone: "504",
    label: "Honduras",
    currency: "HNL",
  },
  {
    code: "HU",
    phone: "36",
    label: "Hungary",
    currency: "HUF",
  },
  {
    code: "IS",
    phone: "354",
    label: "Iceland",
    currency: "ISK",
  },
  {
    code: "IN",
    phone: "91",
    label: "India",
    currency: "USD",
  },
  {
    code: "ID",
    phone: "62",
    label: "Indonesia",
    currency: "IDR",
  },
  {
    code: "IR",
    phone: "98",
    label: "Iran (Islamic Republic of)",
    currency: "IRR",
  },
  {
    code: "IQ",
    phone: "964",
    label: "Iraq",
    currency: "IQD",
  },
  {
    code: "IE",
    phone: "353",
    label: "Ireland",
    currency: "EUR",
  },
  {
    code: "IM",
    phone: "44",
    label: "Isle of Man",
    currency: "GBP",
  },
  {
    code: "IL",
    phone: "972",
    label: "Israel",
    currency: "ILS",
  },
  {
    code: "IT",
    phone: "39",
    label: "Italy",
    currency: "EUR",
  },
  {
    code: "JM",
    phone: "1-876",
    label: "Jamaica",
    currency: "JMD",
  },
  {
    code: "JP",
    phone: "81",
    label: "Japan",
    currency: "USD",
  },
  {
    code: "JE",
    phone: "44",
    label: "Jersey",
    currency: "GBP",
  },
  {
    code: "JO",
    phone: "962",
    label: "Jordan",
    currency: "JOD",
  },
  {
    code: "KZ",
    phone: "7",
    label: "Kazakhstan",
    currency: "KZT",
  },
  {
    code: "KE",
    phone: "254",
    label: "Kenya",
    currency: "KES",
  },
  {
    code: "KI",
    phone: "686",
    label: "Kiribati",
    currency: "AUD",
  },
  {
    code: "KW",
    phone: "965",
    label: "Kuwait",
    currency: "KWD",
  },
  {
    code: "KG",
    phone: "996",
    label: "Kyrgyzstan",
    currency: "KGS",
  },
  {
    code: "LA",
    phone: "856",
    label: "Lao People's Democratic Republic",
    currency: "LAK",
  },
  {
    code: "LV",
    phone: "371",
    label: "Latvia",
    currency: "EUR",
  },
  {
    code: "LB",
    phone: "961",
    label: "Lebanon",
    currency: "LBP",
  },
  {
    code: "LS",
    phone: "266",
    label: "Lesotho",
    currency: "USD",
  },
  {
    code: "LR",
    phone: "231",
    label: "Liberia",
    currency: "LRD",
  },
  {
    code: "LY",
    phone: "218",
    label: "Libya",
    currency: "LYD",
  },
  {
    code: "LI",
    phone: "423",
    label: "Liechtenstein",
    currency: "CHF",
  },
  {
    code: "LT",
    phone: "370",
    label: "Lithuania",
    currency: "EUR",
  },
  {
    code: "LU",
    phone: "352",
    label: "Luxembourg",
    currency: "EUR",
  },
  {
    code: "MG",
    phone: "261",
    label: "Madagascar",
    currency: "USD",
  },
  {
    code: "MW",
    phone: "265",
    label: "Malawi",
    currency: "MWK",
  },
  {
    code: "MY",
    phone: "60",
    label: "Malaysia",
    currency: "MYR",
  },
  {
    code: "MV",
    phone: "960",
    label: "Maldives",
    currency: "MVR",
  },
  {
    code: "ML",
    phone: "223",
    label: "Mali",
    currency: "XOF",
  },
  {
    code: "MT",
    phone: "356",
    label: "Malta",
    currency: "EUR",
  },
  {
    code: "MH",
    phone: "692",
    label: "Marshall Islands",
    currency: "USD",
  },
  {
    code: "MQ",
    phone: "596",
    label: "Martinique",
    currency: "EUR",
  },
  {
    code: "MR",
    phone: "222",
    label: "Mauritania",
    currency: "MRU",
  },
  {
    code: "MU",
    phone: "230",
    label: "Mauritius",
    currency: "MUR",
  },
  {
    code: "YT",
    phone: "262",
    label: "Mayotte",
    currency: "EUR",
  },
  {
    code: "MX",
    phone: "52",
    label: "Mexico",
    currency: "MXN",
  },
  {
    code: "FM",
    phone: "691",
    label: "Micronesia (Federated States of)",
    currency: "USD",
  },
  {
    code: "MC",
    phone: "377",
    label: "Monaco",
    currency: "EUR",
  },
  {
    code: "MN",
    phone: "976",
    label: "Mongolia",
    currency: "MNT",
  },
  {
    code: "ME",
    phone: "382",
    label: "Montenegro",
    currency: "EUR",
  },
  {
    code: "MS",
    phone: "1-664",
    label: "Montserrat",
    currency: "XCD",
  },
  {
    code: "MA",
    phone: "212",
    label: "Morocco",
    currency: "MAD",
  },
  {
    code: "MZ",
    phone: "258",
    label: "Mozambique",
    currency: "MZN",
  },
  {
    code: "MM",
    phone: "95",
    label: "Myanmar",
    currency: "MMK",
  },
  {
    code: "NA",
    phone: "264",
    label: "Namibia",
    currency: "USD",
  },
  {
    code: "NR",
    phone: "674",
    label: "Nauru",
    currency: "AUD",
  },
  {
    code: "NP",
    phone: "977",
    label: "Nepal",
    currency: "NPR",
  },
  {
    code: "NL",
    phone: "31",
    label: "Netherlands",
    currency: "EUR",
  },
  {
    code: "NC",
    phone: "687",
    label: "New Caledonia",
    currency: "XPF",
  },
  {
    code: "NZ",
    phone: "64",
    label: "New Zealand",
    currency: "NZD",
  },
  {
    code: "NI",
    phone: "505",
    label: "Nicaragua",
    currency: "NIO",
  },
  {
    code: "NE",
    phone: "227",
    label: "Niger",
    currency: "XOF",
  },
  {
    code: "NG",
    phone: "234",
    label: "Nigeria",
    currency: "NGN",
  },
  {
    code: "NU",
    phone: "683",
    label: "Niue",
    currency: "NZD",
  },
  {
    code: "NF",
    phone: "672",
    label: "Norfolk Island",
    currency: "AUD",
  },
  {
    code: "MP",
    phone: "1-670",
    label: "Northern Mariana Islands",
    currency: "USD",
  },
  {
    code: "NO",
    phone: "47",
    label: "Norway",
    currency: "NOK",
  },
  {
    code: "OM",
    phone: "968",
    label: "Oman",
    currency: "OMR",
  },
  {
    code: "PK",
    phone: "92",
    label: "Pakistan",
    currency: "PKR",
  },
  {
    code: "PW",
    phone: "680",
    label: "Palau",
    currency: "USD",
  },
  {
    code: "PA",
    phone: "507",
    label: "Panama",
    currency: "USD",
  },
  {
    code: "PG",
    phone: "675",
    label: "Papua New Guinea",
    currency: "PGK",
  },
  {
    code: "PY",
    phone: "595",
    label: "Paraguay",
    currency: "USD",
  },
  {
    code: "PE",
    phone: "51",
    label: "Peru",
    currency: "PEN",
  },
  {
    code: "PH",
    phone: "63",
    label: "Philippines",
    currency: "PHP",
  },
  {
    code: "PN",
    phone: "870",
    label: "Pitcairn",
    currency: "NZD",
  },
  {
    code: "PL",
    phone: "48",
    label: "Poland",
    currency: "PLN",
  },
  {
    code: "PT",
    phone: "351",
    label: "Portugal",
    currency: "EUR",
  },
  {
    code: "PR",
    phone: "1",
    label: "Puerto Rico",
    currency: "USD",
  },
  {
    code: "QA",
    phone: "974",
    label: "Qatar",
    currency: "QAR",
  },
  {
    code: "KR",
    phone: "82",
    label: "Republic of Korea",
    currency: "USD",
  },
  {
    code: "MD",
    phone: "373",
    label: "Republic of Moldova",
    currency: "MDL",
  },
  {
    code: "RO",
    phone: "40",
    label: "Romania",
    currency: "RON",
  },
  {
    code: "RU",
    phone: "7",
    label: "Russian Federation",
    currency: "RUB",
  },
  {
    code: "RW",
    phone: "250",
    label: "Rwanda",
    currency: "USD",
  },
  {
    code: "RE",
    phone: "262",
    label: "Réunion",
    currency: "EUR",
  },
  {
    code: "BL",
    phone: "590",
    label: "Saint Barthélemy",
    currency: "EUR",
  },
  {
    code: "SH",
    phone: "290",
    label: "Saint Helena",
    currency: "SHP",
  },
  {
    code: "KN",
    phone: "1-869",
    label: "Saint Kitts and Nevis",
    currency: "XCD",
  },
  {
    code: "LC",
    phone: "1-758",
    label: "Saint Lucia",
    currency: "XCD",
  },
  {
    code: "MF",
    phone: "590",
    label: "Saint Martin (French Part)",
    currency: "EUR",
  },
  {
    code: "PM",
    phone: "508",
    label: "Saint Pierre and Miquelon",
    currency: "EUR",
  },
  {
    code: "VC",
    phone: "1-784",
    label: "Saint Vincent and the Grenadines",
    currency: "XCD",
  },
  {
    code: "WS",
    phone: "685",
    label: "Samoa",
    currency: "WST",
  },
  {
    code: "SM",
    phone: "378",
    label: "San Marino",
    currency: "EUR",
  },
  {
    code: "ST",
    phone: "239",
    label: "Sao Tome and Principe",
    currency: "STN",
  },
  {
    code: "SA",
    phone: "966",
    label: "Saudi Arabia",
    currency: "SAR",
  },
  {
    code: "SN",
    phone: "221",
    label: "Senegal",
    currency: "XOF",
  },
  {
    code: "RS",
    phone: "381",
    label: "Serbia",
    currency: "RSD",
  },
  {
    code: "SC",
    phone: "248",
    label: "Seychelles",
    currency: "SCR",
  },
  {
    code: "SL",
    phone: "232",
    label: "Sierra Leone",
    currency: "SLL",
  },
  {
    code: "SG",
    phone: "65",
    label: "Singapore",
    currency: "SGD",
  },
  {
    code: "SX",
    phone: "1-721",
    label: "Sint Maarten (Dutch part)",
    currency: "ANG",
  },
  {
    code: "SK",
    phone: "421",
    label: "Slovakia",
    currency: "EUR",
  },
  {
    code: "SI",
    phone: "386",
    label: "Slovenia",
    currency: "EUR",
  },
  {
    code: "SB",
    phone: "677",
    label: "Solomon Islands",
    currency: "SBD",
  },
  {
    code: "SO",
    phone: "252",
    label: "Somalia",
    currency: "SOS",
  },
  {
    code: "ZA",
    phone: "27",
    label: "South Africa",
    currency: "ZAR",
  },
  {
    code: "SS",
    phone: "211",
    label: "South Sudan",
    currency: "SSP",
  },
  {
    code: "ES",
    phone: "34",
    label: "Spain",
    currency: "EUR",
  },
  {
    code: "LK",
    phone: "94",
    label: "Sri Lanka",
    currency: "LKR",
  },
  {
    code: "SD",
    phone: "249",
    label: "Sudan",
    currency: "SDG",
  },
  {
    code: "SR",
    phone: "597",
    label: "Suriname",
    currency: "SRD",
  },
  {
    code: "SJ",
    phone: "47",
    label: "Svalbard and Jan Mayen Islands",
    currency: "NOK",
  },
  {
    code: "SE",
    phone: "46",
    label: "Sweden",
    currency: "SEK",
  },
  {
    code: "CH",
    phone: "41",
    label: "Switzerland",
    currency: "CHF",
  },
  {
    code: "SY",
    phone: "963",
    label: "Syrian Arab Republic",
    currency: "SYP",
  },
  {
    code: "TJ",
    phone: "992",
    label: "Tajikistan",
    currency: "TJS",
  },
  {
    code: "TH",
    phone: "66",
    label: "Thailand",
    currency: "THB",
  },
  {
    code: "MK",
    phone: "389",
    label: "The former Yugoslav Republic of Macedonia",
    currency: "MKD",
  },
  {
    code: "TL",
    phone: "670",
    label: "Timor-Leste",
    currency: "USD",
  },
  {
    code: "TG",
    phone: "228",
    label: "Togo",
    currency: "XOF",
  },
  {
    code: "TK",
    phone: "690",
    label: "Tokelau",
    currency: "NZD",
  },
  {
    code: "TO",
    phone: "676",
    label: "Tonga",
    currency: "TOP",
  },
  {
    code: "TT",
    phone: "1-868",
    label: "Trinidad and Tobago",
    currency: "TTD",
  },
  {
    code: "TN",
    phone: "216",
    label: "Tunisia",
    currency: "TND",
  },
  {
    code: "TR",
    phone: "90",
    label: "Turkey",
    currency: "TRY",
  },
  {
    code: "TM",
    phone: "993",
    label: "Turkmenistan",
    currency: "TMT",
  },
  {
    code: "TC",
    phone: "1-649",
    label: "Turks and Caicos Islands",
    currency: "USD",
  },
  {
    code: "TV",
    phone: "688",
    label: "Tuvalu",
    currency: "AUD",
  },
  {
    code: "UG",
    phone: "256",
    label: "Uganda",
    currency: "USD",
  },
  {
    code: "UA",
    phone: "380",
    label: "Ukraine",
    currency: "UAH",
  },
  {
    code: "AE",
    phone: "971",
    label: "United Arab Emirates",
    currency: "AED",
  },
  {
    code: "GB",
    phone: "44",
    label: "United Kingdom of Great Britain and Northern Ireland",
    currency: "GBP",
  },
  {
    code: "TZ",
    phone: "255",
    label: "United Republic of Tanzania",
    currency: "TZS",
  },
  {
    code: "UM",
    phone: "",
    label: "United States Minor Outlying Islands",
    currency: "USD",
  },
  {
    code: "VI",
    phone: "1-340",
    label: "United States Virgin Islands",
    currency: "USD",
  },
  {
    code: "US",
    phone: "1",
    label: "United States of America",
    currency: "USD",
  },
  {
    code: "UY",
    phone: "598",
    label: "Uruguay",
    currency: "UYU",
  },
  {
    code: "UZ",
    phone: "998",
    label: "Uzbekistan",
    currency: "UZS",
  },
  {
    code: "VU",
    phone: "678",
    label: "Vanuatu",
    currency: "USD",
  },
  {
    code: "VE",
    phone: "58",
    label: "Venezuela (Bolivarian Republic of)",
    currency: "VES",
  },
  {
    code: "VN",
    phone: "84",
    label: "Viet Nam",
    currency: "USD",
  },
  {
    code: "WF",
    phone: "681",
    label: "Wallis and Futuna Islands",
    currency: "XPF",
  },
  {
    code: "EH",
    phone: "212",
    label: "Western Sahara",
    currency: "MAD",
  },
  {
    code: "YE",
    phone: "967",
    label: "Yemen",
    currency: "YER",
  },
  {
    code: "ZM",
    phone: "260",
    label: "Zambia",
    currency: "ZMW",
  },
  {
    code: "ZW",
    phone: "263",
    label: "Zimbabwe",
    currency: "ZWL",
  },
  {
    code: "AX",
    phone: "358",
    label: "Åland Islands",
    currency: "EUR",
  },];