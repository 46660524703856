import React, { useEffect, useState } from 'react';
import firebase from 'firebase/app'
import ConsultantRow from './components/ConsultantRow';
import ClipLoader from "react-spinners/ClipLoader";
import { emptyState, localeToCurrency } from '../../constants/utils';
import config from '../../config/config';
import { TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import TableComponent from '../TableComponent';

const Consultants = () => {
  const [consultants, setConsultants] = useState([])
  const [consultantsCount, setConsultantsCount] = useState(0)
  const [isFetchingData, setIsFetching] = useState(false)
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(200);

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    console.log('Counter shards started fetching.')
    firebase.firestore().doc('/counters/consultants').onSnapshot(snapshot => {
      setConsultantsCount(snapshot.data().count)
    }, error => {
      console.log('Error fetching counter shards:', error)
    })
  }, [])
  
  useEffect(() => {
    const offset = rowsPerPage * page
    const toSend = { limit: rowsPerPage, offset: offset }
    console.log("Fetch started:", performance.now())

    setIsFetching(true)
    setConsultants([])

    firebase.functions().httpsCallable('getStatsForConsultants')(toSend).then(function (result) {
        const stats = result.data.stats

        console.log("Consultants fetched:", stats.length, performance.now())

        if (offset === page * rowsPerPage) {
          const ronConsultants = stats.filter(function(state) {
            return state["consultantLocale"] === "ro";
          })

          const usdConsultants = stats.filter(function(state) {
            return state["consultantLocale"] === "us";
          })

          const eurConsultants = stats.filter(function(state) {
            return state["consultantLocale"] === "it";
          })

          stats.push(emptyState())
          if (config.type !== 'astrologer') {
            stats.push(statsTotal(ronConsultants, "RON"))
            stats.push(statsTotal(eurConsultants, "EUR"))
          }
          stats.push(statsTotal(usdConsultants, "USD"))

          setIsFetching(false)
          setConsultants(stats)
          console.log("STATS COUNT", stats.length)
        }
      }
      ).catch(error => {
        setIsFetching(false)
        alert(error)
      });
  }, [page, rowsPerPage])

  const statsTotal = (stats, initialCurrency) => {
    const toReturn = stats.reduce((sumedStats, currentState) => {
      return {
        name: "TOTAL (" + localeToCurrency(currentState["consultantLocale"]) +  "):", 
        consultantLocale: currentState["consultantLocale"],
        allTimeTotal: sumedStats["allTimeTotal"] + currentState["allTimeTotal"], 
        consultantAllTimeTotal: sumedStats["consultantAllTimeTotal"] + currentState["consultantAllTimeTotal"],
        lokiAllTimeTotal: sumedStats["lokiAllTimeTotal"] + currentState["lokiAllTimeTotal"],
        stripeAllTimeTotal: sumedStats["stripeAllTimeTotal"] + currentState["stripeAllTimeTotal"],
        totalWithdrawn: sumedStats["totalWithdrawn"] + currentState["totalWithdrawn"],
        walletBallance: sumedStats["walletBallance"] + currentState["walletBallance"]
      }
    }, {
      name: "TOTAL (" + initialCurrency + "):", 
      consultantLocale: "ro",
      allTimeTotal: 0, 
      consultantAllTimeTotal: 0,
      lokiAllTimeTotal: 0,
      stripeAllTimeTotal: 0,
      totalWithdrawn: 0,
      walletBallance: 0
    })

    toReturn["consultantID"] = "x" + Math.random()
    return toReturn
  }

  const MyTableHead = () => {
    return (
      <TableHead>
        <TableRow>
          <TableCell className='string_column'>User</TableCell>
          <TableCell className='string_column'>Email</TableCell>
          <TableCell className='date_column'>Created At</TableCell>
          <TableCell className='string_column'>Profile Link</TableCell>
          <TableCell className='numerar_column'>All Transactions (c3+c4+c5)</TableCell>
          <TableCell className='numerar_column'>User Income</TableCell>
          <TableCell className='numerar_column'>{config.title} Income</TableCell>
          <TableCell className='numerar_column'>Team Income</TableCell>
          <TableCell className='numerar_column'>Stripe Income</TableCell>
          <TableCell className='numerar_column'>All Withdrawals</TableCell>
          <TableCell className='numerar_column'>Wallet Ballance (c3-c6)</TableCell>
        </TableRow>
      </TableHead>
    )
  }

  const MyTableBody = () => {
    return (
      <TableBody>
        {consultants.map((element, index) => {
          return <ConsultantRow key={index} snapshotData={element} />
        })}
      </TableBody>
    )
  }

  return (
    <div>
      <h1 id="homepage-title">Consultants</h1>
      
      {isFetchingData ?
        <div className="homepage-loader">
          <ClipLoader size={30} color={"#3f51b5"} loading={isFetchingData} />
        </div>
      :
        <TableComponent
          tableHead={<MyTableHead />}
          tableBody={<MyTableBody />}
          count={consultantsCount}
          page={page}
          handleChangePage={handleChangePage}
          rowsPerPage={rowsPerPage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          rootClass='consultants_table'
        />
      }
    </div>
  )
};

export default Consultants;
