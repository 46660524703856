export default {
  type: 'astrologer',
  apiKey: "AIzaSyC39luPNLVosJH4NTHNnUzzF1IaOx1IsOo",
  authDomain: "astrologer-ea0ff.firebaseapp.com",
  projectId: "astrologer-ea0ff",
  storageBucket: "astrologer-ea0ff.appspot.com",
  messagingSenderId: "171824249818",
  appId: "1:171824249818:web:994078148cb4db98e81ee3",
  title: "Astrologer Connect",
  withoutCorporate: true
}
