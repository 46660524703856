import React, { useContext, useEffect, useState } from 'react';
import { Paper, withStyles, Grid, TextField, Button, FormControlLabel, Checkbox, CircularProgress, useMediaQuery } from '@material-ui/core';
import { Face, Fingerprint } from '@material-ui/icons'
import { FirebaseContext } from '../Firebase'

const styles = theme => ({
  margin: {
      margin: theme.spacing(2),
  },
  padding: {
      padding: theme.spacing(1) 
  }
});

const SignIn = (props) => {
  const { classes, setAuthUser } = props;
  const context = useContext(FirebaseContext)

  const [username, setUsername] = useState("")
  const [password, setPassword] = useState("")
  const [rememberMe, setRememberMe] = useState(false)
  const [signInEnabled, setSignInEnabled] = useState(false)
  const [loading, setLoading] = useState(false)
  
  const widthLessThan900px = useMediaQuery('(max-width:899px)');

  useEffect(() => {
    setSignInEnabled(username.length > 1 && password.length > 1)
  }, [username, password])

  const signInTapped = async () => {
    setLoading(true)
    await context.signIn(username, password, setAuthUser)
    setLoading(false)
  }

  const rememberMeChange = (event) => {
    context.setRememberMe(event.target.checked)
    setRememberMe(event.target.checked)
  }

  const usernameChange = (event) => {
    setUsername(event.target.value);
  };

  const passwordChange = (event) => {
    setPassword(event.target.value);
  };

  return (
    <Paper style={{width: widthLessThan900px ? '75%' : '50%', margin: '0 auto', marginTop: '100px'}} className={classes.padding}>
      <div className={classes.margin}>
        <Grid container spacing={8} alignItems="flex-end">
          <Grid item className='icon_input'>
            <Face />
          </Grid>
          <Grid item md={true} sm={true} xs={true}>
            <TextField onChange={usernameChange} value={username} id="email" label="Email" type="email" fullWidth autoFocus required />
          </Grid>
        </Grid>
        <Grid container spacing={8} alignItems="flex-end">
          <Grid item className='icon_input'>
            <Fingerprint />
          </Grid>
          <Grid item md={true} sm={true} xs={true}>
            <TextField onChange={passwordChange} value={password} id="password" label="Password" type="password" fullWidth required />
          </Grid>
        </Grid>
        <Grid container alignItems="center" justify="space-between">
          <Grid item>
            <FormControlLabel control={
              <Checkbox
                color="primary"
                value = {rememberMe ? "on" : "off"}
                onChange={rememberMeChange}
              />
            } label="Remember me" />
          </Grid>
        </Grid>
        <Grid container justify="center" style={{ marginTop: '10px', minHeight: '40px' }}>
          {loading ?
            <CircularProgress size={17} />
          :
            <Button
              disabled={!signInEnabled}
              onClick={signInTapped}
              variant="outlined"
              color="primary"
              style={{ textTransform: "none" }}
            >Login</Button>
          }
        </Grid>
      </div>
    </Paper>
  )
};

export default withStyles(styles)(SignIn);
