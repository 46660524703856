import React, { useEffect, useState } from 'react';
import firebase from 'firebase/app'
import WithdrawalRow from './components/WithdrawalRow';
import ClipLoader from "react-spinners/ClipLoader";
import TableComponent from '../TableComponent';
import { TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';

const Home = () => {
  const [withdrawals, setWithdrawals] = useState([])
  const [withdrawalsCount, setWithdrawalsCount] = useState(0)
  const [isFetchingData, setIsFetching] = useState(false)
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(200);

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    console.log('Counter shards started fetching.')
    firebase.firestore().doc('/counters/withdrawals').onSnapshot(snapshot => {
      setWithdrawalsCount(snapshot.data().count)
    }, error => {
      console.log('Error fetching counter shards:', error)
    })
  }, [])

  const fetchWithdrawals = () => {
    const offset = rowsPerPage * page
    const toSend = { limit: rowsPerPage, offset: offset }
    console.log("Fetch started:", performance.now())

    setIsFetching(true)
    setWithdrawals([])

    firebase.functions().httpsCallable('getWithdrawals')(toSend).then(function (result) {
        console.log("Withdrawals fetched:", result.data.docs.length)
        if (offset === page * rowsPerPage) {
          setIsFetching(false)
          setWithdrawals(result.data.docs)
        }
    });
  }

  useEffect(fetchWithdrawals, [page, rowsPerPage])

  const setLoadingIndicator = (isVisible) => {
    setIsFetching(isVisible)
  }

  const MyTableHead = () => {
    return (
      <TableHead>
        <TableRow>
          <TableCell className='string_column'>User</TableCell>
          <TableCell className='numerar_column'>Amount</TableCell>
          <TableCell className='date_column'>Date</TableCell>
          <TableCell className='destination_column'>Destination</TableCell>
          <TableCell className='destination_column'>Destination Info</TableCell>
          <TableCell className='actions_column'>Actions</TableCell>
        </TableRow>
      </TableHead>
    )
  }

  const MyTableBody = () => {
    return (
      <TableBody>
        {withdrawals.map((element, index) => {
          return <WithdrawalRow 
                    key={index}
                    childUpdatedData={fetchWithdrawals}
                    setLoadingIndicator={setLoadingIndicator}
                    snapshotData={element.data}
                    snapshotRef={element.docId}
                  />
        })}
      </TableBody>
    )
  }

  return (
      <div>
        <h1 id="homepage-title">Withdrawals</h1>
        
        {isFetchingData ?
          <div className="homepage-loader">
            <ClipLoader size={30} color={"#3f51b5"} loading={isFetchingData} />
          </div>
        :
          <TableComponent
            tableHead={<MyTableHead />}
            tableBody={<MyTableBody />}
            count={withdrawalsCount}
            page={page}
            handleChangePage={handleChangePage}
            rowsPerPage={rowsPerPage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            rootClass='withdrawal_table'
          />
        }
      </div>
  )
};

export default Home;
