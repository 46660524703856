import React, { useEffect, useState } from 'react';
import './styles.css';
import firebase from 'firebase/app'
import 'firebase/firestore'
import AvatarEditor from 'react-avatar-editor'
import Dropzone from 'react-dropzone'
import Slider from '@material-ui/core/Slider';
import ImageUploader from 'react-images-upload';
import { useInput } from './components/FormHook';
import CorporateRow from './components/CorporateRow';
import ClipLoader from "react-spinners/ClipLoader";
import { Select, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import TableComponent from '../TableComponent';

const Corporates = () => {
    const firestore = firebase.firestore()
    const functions = firebase.functions()
    const storage = firebase.storage()

    const [corporates, setCorporates] = useState([])
    const [isFetchingData, setIsFetching] = useState(false)
    const [pageState, setPageState] = useState("list")
    const [avatarImage, setAvatarImage] = useState("")
    const [avatarZoom, setAvatarZoom] = useState(1)
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(200);
  
    const handleChangePage = (_, newPage) => {
      setPage(newPage);
    };
  
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
    };

    const { value:email, bind:bindEmail, reset:resetEmail } = useInput('');
    const { value:name, bind:bindName, reset:resetName } = useInput('');
    const { value:surname, bind:bindSurname, reset:resetSurname } = useInput('');
    const { value:password, bind:bindPassword, reset:resetPassword } = useInput('');
    const { value:consultancyType, bind:bindConsultancyType, reset:resetConsultancyType } = useInput('');
    const { value:corporateLocale, bind:bindCorporateLocale } = useInput('ro');
    const { value:videoPrice, bind:bindVideoPrice, reset:resetVideoPrice } = useInput('');
    const { value:audioPrice, bind:bindAudioPrice, reset:resetAudioPrice } = useInput('');
    const { value:aboutMe, bind:bindAboutMe, reset:resetAboutMe } = useInput('');
    const { value:serviceDetails, bind:bindServiceDetails, reset:resetServiceDetails } = useInput('');
    const { value:legalName, bind:bindLegalName, reset:resetLegalName } = useInput('');
    const { value:legalCUI, bind:bindLegalCUI, reset:resetLegalCUI } = useInput('');
    const { value:legalAddress, bind:bindLegalAddress, reset:resetLegalAddress } = useInput('');

    const { value:audio15Price, bind:bindAudio15Price, reset:resetAudio15Price } = useInput('');
    const { value:audio30Price, bind:bindAudio30Price, reset:resetAudio30Price } = useInput('');
    const { value:audio45Price, bind:bindAudio45Price, reset:resetAudio45Price } = useInput('');
    const { value:audio60Price, bind:bindAudio60Price, reset:resetAudio60Price } = useInput('');
    const { value:audio75Price, bind:bindAudio75Price, reset:resetAudio75Price } = useInput('');
    const { value:audio90Price, bind:bindAudio90Price, reset:resetAudio90Price } = useInput('');

    const { value:video15Price, bind:bindVideo15Price, reset:resetVideo15Price } = useInput('');
    const { value:video30Price, bind:bindVideo30Price, reset:resetVideo30Price } = useInput('');
    const { value:video45Price, bind:bindVideo45Price, reset:resetVideo45Price } = useInput('');
    const { value:video60Price, bind:bindVideo60Price, reset:resetVideo60Price } = useInput('');
    const { value:video75Price, bind:bindVideo75Price, reset:resetVideo75Price } = useInput('');
    const { value:video90Price, bind:bindVideo90Price, reset:resetVideo90Price } = useInput('');

    let avatarEditor = undefined
    const setEditorRef = (editor) => {
        avatarEditor = editor
    }

    const categories = [
        { label: "Lawyers", value: "lawyers" },
        { label: "Doctors", value: "doctors" },
        { label: "IT Guys", value: "it_guys"},
        { label: "Psychologists", value: "psychologists" },
        { label: "Teachers", value: "teachers" },
        { label: "Accountants", value: "accountants" },
        { label: "Spirituals", value: "spirituals" },
        { label: "Coaches", value: "coaches" },
        { label: "Others", value: "others" },
    ];

    const handleSubmit = (evt) => {
        evt.preventDefault();

        if(name.length === 0) {
            alert(`Name cannot be empty.`);
            return
        }

        const locales = ["ro", "us", "it"]
        if(!locales.includes(corporateLocale)) {
            alert(`Invalid locale: ${corporateLocale}`);
            return
        }

        const audioBookings = []
        audioBookings.push(getCents(audio15Price) ?? 0)
        audioBookings.push(getCents(audio30Price) ?? 0)
        audioBookings.push(getCents(audio45Price) ?? 0)
        audioBookings.push(getCents(audio60Price) ?? 0)
        audioBookings.push(getCents(audio75Price) ?? 0)
        audioBookings.push(getCents(audio90Price) ?? 0)

        const videoBookings = []
        videoBookings.push(getCents(video15Price) ?? 0)
        videoBookings.push(getCents(video30Price) ?? 0)
        videoBookings.push(getCents(video45Price) ?? 0)
        videoBookings.push(getCents(video60Price) ?? 0)
        videoBookings.push(getCents(video75Price) ?? 0)
        videoBookings.push(getCents(video90Price) ?? 0)

        const toSend = {
            email: email,
            password: password,
            consultant: true,
            corporate:true,
            name: name,
            surname: surname,
            acceptedTOS: true,
            acceptedPrivacy: true,
            consultancyType: consultancyType,
            locale: corporateLocale,
            audioBookingAmounts: audioBookings,
            videoBookingAmounts: videoBookings,
            legalName: legalName,
            legalTIN: legalCUI,
            legalAddress: legalAddress
        }

        const toSendVideoPrice = getCents(videoPrice)
        if(toSendVideoPrice) {
            toSend["videoChargeAmount"] = toSendVideoPrice
        }

        const toSendAudioPrice = getCents(audioPrice)
        if(toSendAudioPrice) {
            toSend["audioChargeAmount"] = toSendAudioPrice
        }

        const consultantCategories = selectedCategories.map(cat => { return cat.value })
        toSend["corporateCategories"] = consultantCategories
        console.log("Categories:", consultantCategories)

        setIsFetching(true)

        const avatarData = avatarEditor.getImageScaledToCanvas().toDataURL()
        functions.httpsCallable('createUserFlow')(toSend).then(function (result) {
            const uid = result.data.uid
            if(uid) {
                functions.httpsCallable('verifyUserEmail')({ userID: uid }).then(function (result) {
                    firestore.collection("/users").doc(uid).update({aboutMe: aboutMe, serviceDetails: serviceDetails}).catch((error) => {
                        console.log(error)
                    })

                    if(avatarImage !== "") {
                        const avatarRef = storage.ref().child('images/'+uid+'.jpg');
                        avatarRef.putString(avatarData, 'data_url').catch(error => {
                            console.log(error)
                        });
                    }

                    setIsFetching(false)
                    resetForm()
                    setPageState("list")
                }).catch((error) => {
                    setIsFetching(false)
                    alert(error)
                });
            } else {
                setIsFetching(false)
                alert("No user uid received.")
            }
        }).catch((error) => {
            setIsFetching(false)
            alert(error)
        });
    }

    const resetForm = () => {
        resetName()
        resetSurname()
        resetEmail()
        resetPassword()
        resetVideoPrice()
        resetAudioPrice()
        resetLegalAddress()
        resetLegalCUI()
        resetLegalName()
        resetConsultancyType()
        resetAboutMe()
        resetServiceDetails()
        resetAudio15Price()
        resetAudio30Price()
        resetAudio45Price()
        resetAudio60Price()
        resetAudio75Price()
        resetAudio90Price()

        resetVideo15Price()
        resetVideo30Price()
        resetVideo45Price()
        resetVideo60Price()
        resetVideo75Price()
        resetVideo90Price()

        setAvatarZoom(1)
        setAvatarImage("")
        setSelectedCategories([])
    }

    function getCents(value) {
        if (value === "") return undefined
        const price = parseFloat(value)
        const roundedPrice = round(price, 1) // convert 2.345 to 2.3, 24.23223 to 24.3 and soo on. we reduce the number to max 1 decimal
        return Math.round(roundedPrice * 100) // convert from currency to cents
    }

    function round(value, precision) {
        const multiplier = Math.pow(10, precision || 0);
        return Math.round(value * multiplier) / multiplier;
    }

    useEffect(() => {
        if(pageState !== "list") return
        console.log('Corporates fetching started.')
        setIsFetching(true)

        setCorporates([])
        firebase.firestore().collection('/users').where("corporate", "==", true).where("consultant", "==", true).onSnapshot(snapshot => {
            setCorporates(snapshot.docs)
            setIsFetching(false)
            console.log("Corporate consultants fetched. Count:", snapshot.docs.length)
        }, error => {
            console.log('Error fetching consultants:', error)
            setIsFetching(false)
        })
    }, [page, pageState, rowsPerPage])

    const handleDrop = dropped => {
        setAvatarImage(dropped[0])
    }

    const clickedStateButton = (event) => {
        event.preventDefault()

        if(pageState === "list") {
            setPageState("add")
        } else if(pageState === "add" || pageState === "edit") {
            resetForm()
            setPageState("list")
        }
    }

    const handleZoomChange = (_, newValue) => {
        setAvatarZoom(newValue)
    };
    
    const onImageSelect = (pictures) => {
        setAvatarImage(pictures[0])
    }

    const MyTableHead = () => {
      return (
        <TableHead>
          <TableRow>
            <TableCell className='string_column'>Corporate</TableCell>
            <TableCell className='kind_column'>Locale</TableCell>
            <TableCell className='numerar_column'>Audio price</TableCell>
            <TableCell className='numerar_column'>Video price</TableCell>
            <TableCell className='numerar_column'>Audio booking prices</TableCell>
            <TableCell className='numerar_column'>Video booking prices</TableCell>
            <TableCell className='string_column'>Actions</TableCell>
          </TableRow>
        </TableHead>
      )
    }
  
    const MyTableBody = () => {
      return (
        <TableBody>
            {corporates.map((element, index) => {
                return <CorporateRow key={index} snapshotData={element.data()}/>
            })}
        </TableBody>
      )
    }

    return (
        <div>
            <h1 id="homepage-title">Corporate</h1>
            <button onClick={clickedStateButton} id="add-corporate">
                {pageState === "list" && "Add corporate"}
                {pageState === "add" && "Back to listing"}
                {pageState === "edit" && "Back to listing"}
            </button>
            {isFetchingData ?
                <div class="homepage-loader">
                    <ClipLoader size={30} color={"#3f51b5"} loading={isFetchingData} />
                </div>
            :
                <>
                    {pageState === "list" &&
                        <TableComponent
                            tableHead={<MyTableHead />}
                            tableBody={<MyTableBody />}
                            count={corporates.length}
                            page={page}
                            handleChangePage={handleChangePage}
                            rowsPerPage={rowsPerPage}
                            handleChangeRowsPerPage={handleChangeRowsPerPage}
                            rootClass='corporate_table'
                        />
                    }
                    {pageState === "add" &&
                        <form id='form-add' onSubmit={handleSubmit}>
                            <div id='upload_image'>
                                <Dropzone
                                    id="form-item"
                                    onDrop={handleDrop}
                                    noKeyboard
                                    noClick
                                >
                                    {({ getRootProps, getInputProps }) => (
                                        <div {...getRootProps()}>
                                        <AvatarEditor
                                            image={avatarImage}
                                            scale={avatarZoom}
                                            id="form-item"
                                            ref={setEditorRef}
                                        />
                                        <input {...getInputProps()} />
                                        </div>
                                    )}
                                </Dropzone>
                                <ImageUploader
                                    fileContainerStyle={{width: "350px", "margin": "auto"}}
                                    style={{ width: 'auto' }}
                                    label={"Max file size: 5mb, accepted: jpg | png | jpeg"}
                                    withIcon={true}
                                    buttonText='Choose image'
                                    onChange={onImageSelect}
                                    imgExtension={['.jpg', '.png', '.jpeg']}
                                    maxFileSize={5242880}
                                    singleImage={true}
                                />
                            </div>
                            <label id = "form-item">
                                <b>Name: </b>
                                <input type="text" {...bindName} id="form-input" />
                            </label>
                            <label id = "form-item">
                                <b>Surname: </b>
                                <input type="text" {...bindSurname} id="form-input" />
                            </label>
                            <label id = "form-item">
                                <b>Email: </b>
                                <input type="email" {...bindEmail} id="form-input" />
                            </label>
                            <label id = "form-item">
                                <b>Password: </b>
                                <input type="text" {...bindPassword} id="form-input" />
                            </label>
                            <label id = "form-item">
                                <b>Expertise (eg. start up max 40 chars): </b>
                                <input type="text" {...bindConsultancyType} id="form-input" />
                            </label>
                            <label id = "form-item">
                                <b>Categories: </b>
                                <Select
                                    value={selectedCategories}
                                    onChange={(e) => {
                                        setSelectedCategories(e.target.value)
                                    }}
                                    options={categories}
                                    className="form-select-categories"
                                    multiple={true}
                                >
                                    {categories.map((category) => (
                                        <option
                                            className={`${selectedCategories.includes(category.value) ? 'selected_category' : ''}`}
                                            key={category.value}
                                            value={category.value}
                                        >
                                            {category.label}
                                        </option>
                                    ))}
                                </Select>
                            </label>
                            <label id = "form-item">
                                <b>Locale (ro / it / us): </b>
                                <input type="text" {...bindCorporateLocale} id="form-input" readOnly/>
                            </label>
                            <label id = "form-item">
                                <b>About me: </b>
                                <textarea {...bindAboutMe} id="form-about-me" />
                            </label>
                            <label id = "form-item">
                                <b>Service details: </b>
                                <textarea {...bindServiceDetails} id="form-about-me" />
                            </label>
                            <label id = "form-item">
                                <b>Avatar zoom:</b>
                                <Slider
                                    id="zoom-slider"
                                    value={avatarZoom}
                                    min={1}
                                    max={4}
                                    step={0.01}
                                    onChange={handleZoomChange}
                                    aria-labelledby="continuous-slider"
                                />
                            </label>
                            <label id = "form-item">
                                <b>Booking audio prices</b>
                            </label>
                            <label id = "form-item">
                                <span>15 mins:</span>
                                <input type="number" {...bindAudio15Price} id="form-input" />
                            </label>
                            <label id = "form-item">
                                <span>30 mins:</span>
                                <input type="number" {...bindAudio30Price} id="form-input" />
                            </label>
                            <label id = "form-item">
                                <span>45 mins:</span>
                                <input type="number" {...bindAudio45Price} id="form-input" />
                            </label>
                            <label id = "form-item">
                                <span>60 mins:</span>
                                <input type="number" {...bindAudio60Price} id="form-input" />
                            </label>
                            <label id = "form-item">
                                <span>75 mins:</span>
                                <input type="number" {...bindAudio75Price} id="form-input" />
                            </label>
                            <label id = "form-item">
                                <span>90 mins:</span>
                                <input type="number" {...bindAudio90Price} id="form-input" />
                            </label>
                            <label id = "form-item">
                                <b>Booking video prices</b>
                            </label>
                            <label id = "form-item">
                                <span>15 mins:</span>
                                <input type="number" {...bindVideo15Price} id="form-input" />
                            </label>
                            <label id = "form-item">
                                <span>30 mins:</span>
                                <input type="number" {...bindVideo30Price} id="form-input" />
                            </label>
                            <label id = "form-item">
                                <span>45 mins:</span>
                                <input type="number" {...bindVideo45Price} id="form-input" />
                            </label>
                            <label id = "form-item">
                                <span>60 mins:</span>
                                <input type="number" {...bindVideo60Price} id="form-input" />
                            </label>
                            <label id = "form-item">
                                <span>75 mins:</span>
                                <input type="number" {...bindVideo75Price} id="form-input" />
                            </label>
                            <label id = "form-item">
                                <span>90 mins:</span>
                                <input type="number" {...bindVideo90Price} id="form-input" />
                            </label>
                            <label id = "form-item">
                                <b>Audio price (one decimal only eg. 2.3): </b>
                                <input type="number" {...bindAudioPrice} id="form-input" />
                            </label>
                            <label id = "form-item">
                                <b>Video price (one decimal only eg. 2.3): </b>
                                <input type="number" {...bindVideoPrice} id="form-input" />
                            </label>
                            <label id = "form-item">
                                <b>Legal entity</b>
                            </label>
                            <label id = "form-item">
                                <span>Entity name:</span>
                                <input type="text" {...bindLegalName} id="form-input" />
                            </label>
                            <label id = "form-item">
                                <span>Entity CUI:</span>
                                <input type="text" {...bindLegalCUI} id="form-input" />
                            </label>
                            <label id = "form-item">
                                <span>Entity address:</span>
                                <input type="text" {...bindLegalAddress} id="form-input" />
                            </label>
                            <div id ="form-submit">
                                <input type="submit" value="Submit"/>
                            </div>
                        </form>
                    }
                </>
            }
        </div>
    )
};

export default Corporates;
