import React, { useContext, useState } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import { Link, useHistory } from 'react-router-dom'
import * as ROUTES from '../../constants/routes';
import { FirebaseContext } from '../Firebase'
import config from '../../config/config';
import { Drawer, IconButton, useMediaQuery, MenuList, MenuItem } from "@material-ui/core";
import MenuIcon from '@material-ui/icons/Menu';

const Navigation = (props) => {
  const { authUser, setAuthUser, isFetching } = props
  const context = useContext(FirebaseContext)
  const history = useHistory()
  const [open, setOpen] = useState(false);

  const initialValueTab = () => {
    switch(window.location.pathname) {
      case '/consultants': return 1
      case '/clients': return 2
      case '/corporate': return 3
      default: return 0
    }
  }
  const [valueTab, setValueTab] = useState(initialValueTab());
  
  const widthLessThan900px = useMediaQuery('(max-width:899px)');

  const handleLogOut = () => {
    setOpen(false)
    context.auth.signOut()
    setAuthUser(null)
    setValueTab(0)
    history.push('/')
  }

  const handleDrawer = () => {
    setOpen(open => !open);
  };

  return (
    <AppBar position="static">
      <Toolbar>
        <Typography variant="h6" style={{ marginRight: 30 }}>
          {config.title}
        </Typography>
        {authUser && !isFetching &&
          <>
            {widthLessThan900px ? 
              <>
                <IconButton
                    style={{ cursor: 'pointer', position: 'absolute', right: '20px' }}
                    color="inherit"
                    onClick={handleDrawer}
                >
                    <MenuIcon />
                </IconButton>
                <Drawer
                    className='drawer'
                    anchor='right'
                    open={open}
                    onClose={handleDrawer}
                  >
                  <MenuList>
                    <MenuItem>
                      <Tab label="Withdrawals" component={Link} to={ROUTES.HOME} />
                    </MenuItem>
                    <MenuItem>
                      <Tab label="Consultants"  component={Link} to={ROUTES.CONSULTANTS} />
                    </MenuItem>
                    <MenuItem>
                      <Tab label="Clients"  component={Link} to={ROUTES.CLIENTS} />
                    </MenuItem>
                    {!config.withoutCorporate &&
                      <MenuItem>
                          <Tab label="Corporate"  component={Link} to={ROUTES.CORPORATE} />
                      </MenuItem>
                    }
                    {config.type === 'yaloop' &&
                      <MenuItem>
                          <Tab label="Credits"  component={Link} to={ROUTES.CREDITS} />
                      </MenuItem>
                    }
                    <MenuItem>
                      <Tab label="Log Out" onClick={handleLogOut} />
                    </MenuItem>
                  </MenuList>
                </Drawer>
              </>
            :
              <Tabs value={valueTab} onChange={(e, value) => setValueTab(value)}>
                  <Tab label="Withdrawals" component={Link} to={ROUTES.HOME} />
                  <Tab label="Consultants"  component={Link} to={ROUTES.CONSULTANTS} />
                  <Tab label="Clients"  component={Link} to={ROUTES.CLIENTS} />
                  {!config.withoutCorporate &&
                    <Tab label="Corporate"  component={Link} to={ROUTES.CORPORATE} />
                  }
                  {config.type === 'yaloop' &&
                    <Tab label="Credits"  component={Link} to={ROUTES.CREDITS} />
                  }
                  <Tab label="Log Out" onClick={handleLogOut} to={ROUTES.HOME} />
              </Tabs>
            }
          </>
        }
      </Toolbar>
    </AppBar>
  )
};

export default Navigation;
