import React, { useEffect, useState } from 'react';
import firebase from 'firebase';
import { prettifyAmount } from '../../../constants/utils';
import { TableCell, TableRow } from '@material-ui/core';

const CorporateRow = (props) => {
    const [snapshotData, setSnapshotData] = useState({
        ...props.snapshotData,
        emailValidated: true
    })

    useEffect(() => {
        firebase.functions().httpsCallable('emailIsValidated')({ userID: snapshotData.userID }).then(function (result) {
            const isValidated = result.data["emailVerified"]
            if(isValidated) {
                setSnapshotData((oldSnapshotData) => {
                    return {
                        ...oldSnapshotData,
                        emailValidated: isValidated
                    }
                })
            }
        }).catch((error) => {
            console.log(error)
        });
    }, [snapshotData])

    const checkVerifiedEmail = () => {
        firebase.functions().httpsCallable('emailIsValidated')({ userID: snapshotData.userID }).then(function (result) {
            const isValidated = result.data["emailVerified"]
            if(isValidated) {
                snapshotData.emailValidated = isValidated
            }
        }).catch((error) => {
            alert(error)
        });
    }

    const clickedVerifyEmail = (event) => {
        event.preventDefault()
        console.log("Verify email.")
        firebase.functions().httpsCallable('verifyUserEmail')({ userID: snapshotData.userID }).then(function () {
            console.log("verified.")
            checkVerifiedEmail()
        }).catch((error) => {
            alert(error)
        });
    }

    const bookingPrices = (pricesArray) => {
        let toReturn = ""
        if(!pricesArray) {
            return toReturn
        }
        const sessionLengthArray = ["15m: ", "30m: ", "45m: ", "60m: ", "75m: ", "90m: "]
        pricesArray.forEach(function (value, i) {
            toReturn = toReturn + (value === 0 ? "" : sessionLengthArray[i]) + " " + (value === 0 ? "" : prettifyAmount(snapshotData.corporateLocale, value) + ",")
        });
        return toReturn
    }

    return (
        <TableRow>
            <TableCell className='string_column'>{snapshotData.name + " " + snapshotData.surname}</TableCell>
            <TableCell className='kind_column'>{snapshotData.corporateLocale}</TableCell>
            <TableCell className='numerar_column'>{prettifyAmount(snapshotData.locale, snapshotData.audioPrice)}</TableCell>
            <TableCell className='numerar_column'>{prettifyAmount(snapshotData.locale, snapshotData.videoPrice)}</TableCell>
            <TableCell className='numerar_column'>{bookingPrices(snapshotData.audioBookingAmounts)}</TableCell>
            <TableCell className='numerar_column'>{bookingPrices(snapshotData.videoBookingAmounts)}</TableCell>
            <TableCell className='numerar_column'>{!snapshotData.emailValidated && <button onClick={clickedVerifyEmail}>Validate Email</button>}</TableCell>
        </TableRow>
    )
}

export default CorporateRow;
