import React, { useEffect, useState } from 'react';
import firebase from 'firebase';
import Popup from 'reactjs-popup'
import RejectPopup from './RejectPopup/RejectPopup';
import { prettifyAmount } from '../../../constants/utils';
import { TableCell, TableRow } from '@material-ui/core';

const WithdrawalRow = (props) => {
    const [snapshotData, setSnapshotData] = useState({
        ...props.snapshotData,
        walletBalance: "Loading...",
        canShowBankInfo: false
    })

    useEffect(() => {
        const fetchData = async () => {
            const amount = snapshotData.amount
            const prettyAmount = prettifyAmount("", amount)
            const date = new Date(snapshotData.date._seconds * 1000).toLocaleString()
            const withdrawalStatus = snapshotData.status
            const masterAccount = snapshotData.teamManagerID
            let rejectedReason = snapshotData.reason
            if (!rejectedReason) {
                rejectedReason = "None provided."
            }

            let withdrawalAccount = "Bank account" // "bank_account" / "paypal_account"
            const isMaster = !!masterAccount
            const destinationAccount = snapshotData.withdrawalAccount
            if (destinationAccount === "bank_account") {
                withdrawalAccount = "Bank account" + (isMaster ? "(T)" : "")
            } else if ( destinationAccount === "paypal_account") {
                withdrawalAccount = "Paypal account" + (isMaster ? "(T)" : "")
            }

            let newSnapshotData =  {
                ...snapshotData,
                userRef: snapshotData.user ?? snapshotData.teamManagerID,
                user: "Loading...",
                bankMessage: "Loading..",
                amount: prettyAmount,
                numberAmount: snapshotData.amount,
                date: date,
                withdrawalStatus: withdrawalStatus,
                rejectedReason: rejectedReason,
                withdrawalAccount: withdrawalAccount,
                masterAccount: masterAccount,
                isTeamWithdrawal: snapshotData.teamWithdrawalID !== undefined && snapshotData.teamWithdrawalID !== null
            }

            if (masterAccount) {
                // we must fetch team manager financial info
                const snapshotUser = await firebase.firestore().collection('/users').doc(masterAccount).get()
                const masterData = snapshotUser.data()
                if (masterData) {
                    const bankAccount = masterData["bank_account"]
                    const paypalAccount = masterData["paypal_account"]
                    if(!destinationAccount || destinationAccount === "bank_account") {
                        const accountData = await fetchAccount(bankAccount, false)
                        newSnapshotData = {
                            ...newSnapshotData,
                            ...accountData
                        }
                    } else if(destinationAccount && destinationAccount === "paypal_account") {
                        const accountData = await fetchAccount(paypalAccount, true)
                        newSnapshotData = {
                            ...newSnapshotData,
                            ...accountData
                        }
                    }
                }
            }

            const userData = await firebase.firestore().collection('/users').doc(snapshotData.user ?? snapshotData.teamManagerID).get()
            const allData = userData.data()
            if (allData) {
                const name = allData["name"]
                const surname = allData["surname"]
                const locale = allData["locale"]
                newSnapshotData = {
                    ...newSnapshotData,
                    user: (name + " " + surname),
                    amount: prettifyAmount(locale, amount),
                    locale: locale
                }

                if (masterAccount) {
                    setSnapshotData({
                        ...newSnapshotData,
                        isFetched: true
                    })
                    return
                }

                const bankAccount = allData["bank_account"]
                const paypalAccount = allData["paypal_account"]
                if(!destinationAccount || destinationAccount === "bank_account") {
                    const accountData = await fetchAccount(bankAccount, false)
                    newSnapshotData = {
                        ...newSnapshotData,
                        ...accountData
                    }
                } else if(destinationAccount && destinationAccount === "paypal_account") {
                    const accountData = await fetchAccount(paypalAccount, true)
                    newSnapshotData = {
                        ...newSnapshotData,
                        ...accountData
                    }
                }
            }

            setSnapshotData({
                ...newSnapshotData,
                isFetched: true
            })
        }

        if (!snapshotData.isFetched) {
            fetchData()
        }
    }, [snapshotData])

    const fetchAccount = async (account, isPaypal) => {
        const accountData = {
            bankMessage :"Click to see details"
        }
        if (!account) {
            accountData.bankMessage = isPaypal ? "Invalid Paypal account!" : "Invalid bank account!"
        } else {
            const snapshot = await account.get()
            const data = snapshot.data()
            if (data) {
                const changed = Object.keys(data).map(each => {
                    return each + ": " + data[each]
                })
                accountData.withdrawalData = changed
            }
        }
        return accountData
    }

    const clickedApprove = (event) => {
        event.preventDefault()

        const snapshotRef = props.snapshotRef
        const teamWithdrawalID = snapshotData.teamWithdrawalID

        if(snapshotData.isTeamWithdrawal) {
          // we approve a team withdrawal
          const toSend = {teamWithdrawalID: teamWithdrawalID, operation: "approve"}
          setLoadingIndicator(true)
          firebase.functions().httpsCallable('updateTeamWithdrawal')(toSend).then(() => {
                reloadParent()
            }).catch(error => {
                console.log("ERROR" + error)
            }).finally( () => {
                setLoadingIndicator(false)
            });
        } else {
          // we approve a normal withdrawal
          setLoadingIndicator(true)
          firebase.firestore().collection('withdrawals').doc(snapshotRef).update({ status: "approved" }).then(() => {
            reloadParent()
          }).catch(error => {
            console.log("ERROR" + error)
          }).finally( () => {
            setLoadingIndicator(false)
        });
        }
    }

    const calculateWallet = () => {
        if (snapshotData.isTeamWithdrawal) {
            setSnapshotData(oldSnapshotData => {
                return {
                    ...oldSnapshotData,
                    canShowBankInfo: true
                }
            })
            return
        }

        const userRef = snapshotData.userRef
        const balance = firebase.functions().httpsCallable("getConsultantBalance")
        console.log("Getting balance with " + userRef)
        balance({ consultantID: userRef, consultantLocale: snapshotData.locale, onlyApproved: true }).then(result => {
            const res = result.data
            console.log("Got result: " + res.walletBalance)
            const walletBalance = res.walletBalance
            const prettyWallet = prettifyAmount(snapshotData.locale, walletBalance)
            if (walletBalance < snapshotData.numberAmount && snapshotData.withdrawalStatus === "pending") {
                setSnapshotData(oldSnapshotData => {
                    return {
                        ...oldSnapshotData,
                        walletBalance: prettyWallet,
                        canShowBankInfo: true,
                        withdrawalData: ["Invalid wallet amount, please contact devs!"]
                    }
                })
            } else {
                setSnapshotData(oldSnapshotData => {
                    return {
                        ...oldSnapshotData,
                        walletBalance: prettyWallet,
                        canShowBankInfo: true,
                    }
                })
            }
        })
    }

    const reloadParent = () => {
        props.childUpdatedData()
    }

    const setLoadingIndicator = (isVisible) => {
        props.setLoadingIndicator(isVisible)
    }

    return (
        <TableRow>
            <TableCell className='string_column'>{snapshotData.user}</TableCell>
            <TableCell className='numerar_column'>{snapshotData.amount}</TableCell>
            <TableCell className='date_column'>{typeof snapshotData.date === 'string' ? snapshotData.date : ''}</TableCell>
            <TableCell className='destination_column'>{snapshotData.withdrawalAccount}</TableCell>
            <TableCell className='destination_column'>
                <Popup onOpen={calculateWallet} contentStyle={{ width: "300px" }} trigger={<button> {snapshotData.bankMessage}</button>}>
                    Wallet balance: {snapshotData.isTeamWithdrawal === true ? "Validated on backend." : snapshotData.walletBalance}
                    {snapshotData.withdrawalData && snapshotData.canShowBankInfo && snapshotData.withdrawalData.map(each => {
                        return <div key={each}>{each}</div>
                    })}
                </Popup>
            </TableCell>
            <TableCell className='actions_column'>
                {snapshotData.status === "pending" && <button onClick={clickedApprove}>Approve</button>}
                {snapshotData.status === "pending" && <b>&nbsp;&nbsp;/&nbsp;&nbsp;</b>}
                {snapshotData.status === "pending" && 
                    <RejectPopup
                        childUpdatedData={reloadParent} 
                        setLoadingIndicator={setLoadingIndicator}
                        withdrawalData={snapshotData} 
                        snapshotRef={props.snapshotRef}
                    />
                }
                {snapshotData.status === "approved" && <div>Confirmed!</div>}
                {snapshotData.status === "rejected" && <div>Rejected! &nbsp; &nbsp;
                    <Popup position='left top' contentStyle={{ width: "300px" }} trigger={<button> Details </button>}>
                        <p>Reason:</p>
                        <p>{snapshotData.reason}</p>
                    </Popup>
                </div>}
            </TableCell>
        </TableRow>
    )
}

export default WithdrawalRow;
