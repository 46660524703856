import app from 'firebase/app';
import 'firebase/auth';
import config from '../../config/config'

class Firebase {
  constructor() {
    app.initializeApp(config);
    this.auth = app.auth()
  }

  setRememberMe = (on) => { 
    this.auth.setPersistence(on ? app.auth.Auth.Persistence.LOCAL : app.auth.Auth.Persistence.SESSION)
  }

  signIn = async (email, password, setAuthUser) => {
    try {
      await this.auth.signInWithEmailAndPassword(email, password)
    } catch (error) {
      alert("Invalid admin username or password!")
      setAuthUser(null)
    }
  }
}

export default Firebase;